import styled from '@emotion/styled';
import { MenuItem, Select, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import NavBar from './components/NavBar';
import { RequireAuth, useAuth } from './components/Providers/AuthProvider';
import { useFeatures } from './components/Providers/FeatureProvider';
import { useJoyride } from './components/Providers/JoyrideProvider';
import Chat from './pages/Chat';
import FAQs from './pages/FAQs';
import Login from './pages/Login';
import Prompts from './pages/Prompts';
import ReleaseNotes from './pages/RealeaseNotes';
import UserStatsPage from './pages/UserStats';
import { updateUser } from './services/users';
import { GuidedSteps } from './Types/guides';
import { User } from './Types/user';

const FullPageContainer = styled('div')({
  // padding: '24px',
  // These styles are because of the default Mui header responsiveness
  '@media (min-width: 0)': {
    height: 'calc(100vh - 56px)',
  },
  '@media (min-width: 600px)': {
    height: 'calc(100vh - 64px)',
  },
  overflowY: 'hidden',
  position: 'relative',
});

function ProvidedApp() {
  const [llm, setLLM] = useState<string>('jbt-gen-ai-gpt-4o');
  const { tourState, setTourState } = useJoyride();
  const { currentUser } = useAuth();
  const features = useFeatures();
  const navigate = useNavigate();

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { lifecycle, step, action } = data;
    if (lifecycle === 'complete') {
      if (action === 'next' && step.title === 'GPT 4 is here!') {
        navigate('/prompts');
        setTourState({ ...tourState, ...{ stepIndex: 1 } });
      }
      if (action === 'close' && step.title === 'GPT 4 is here!') {
        GuidedSteps[0].disableBeacon = false;
        setTourState({ ...tourState, ...{ stepIndex: 0, run: true, tourActive: false } });
      }
      if (action === 'prev') {
        setTourState({ ...tourState, ...{ stepIndex: 0 } });
      }
      if (action === 'next' && step.title === 'New Model Filters') {
        setTourState({ ...tourState, ...{ run: false, stepIndex: 0, tourActive: false } });
        await updateUser({ ...currentUser, ...{ metadata: { tourCompleted: true } } } as User);
      }
      if (action === 'close' && step.title === 'New Model Filters') {
        GuidedSteps[1].disableBeacon = false;
        setTourState({ ...tourState, ...{ run: true, stepIndex: 0, tourActive: false } });
      }
    }
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={tourState.run}
        stepIndex={tourState.stepIndex}
        steps={tourState.steps}
        spotlightClicks={true}
        locale={{
          last: 'OK',
        }}
        styles={{
          buttonNext: {
            backgroundColor: '#092b49',
            borderRadius: '0px',
          },
          buttonBack: {
            color: '#092b49',
          },
          beaconInner: {
            backgroundColor: '#092b49',
          },
          beaconOuter: {
            borderColor: '#092b49',
            backgroundColor: 'rgba(9, 43, 73, 0.5)',
          },
          options: {
            zIndex: 9999,
          },
        }}
      />
      <NavBar />
      <FullPageContainer>
        {(features.gpt4 || features.gpt4_turbo_128k || features.gpt35_turbo_16k) && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '20px',
              display: 'flex',
              alignItems: 'center',
              background: 'white',
              zIndex: '10',
              borderRadius: '4px',
            }}
          >
            <Tooltip
              title={
                <Typography variant="caption">
                  The LLM selected will be used for all prompts and conversations
                </Typography>
              }
              placement="left"
              sx={{ height: '40px', width: '40px' }}
            >
              <Select
                value={llm}
                id="llm-select"
                onChange={(event) => setLLM(event.target.value)}
                sx={{
                  '& > *': { fontFamily: 'Noto Sans' },
                }}
                renderValue={(value) => {
                  switch (value) {
                    case 'GPT_4_32K':
                      return <Typography>GPT 4</Typography>;
                    case 'GPT_35_TURBO':
                      return <Typography>GPT 3.5</Typography>;
                    case 'GPT_4':
                      return <Typography>GPT 4 (non 32k)</Typography>;
                    case 'GPT_4_TURBO':
                      return <Typography>GPT 4 Turbo</Typography>;
                    default:
                      return <Typography>GPT 4</Typography>;
                  }
                }}
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                  MenuListProps: { disablePadding: true },
                }}
                size="small"
              >
                <MenuItem
                  value={'GPT_4_32K'}
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                >
                  GPT 4{' '}
                  <Typography style={{ color: 'grey', fontSize: '14px' }}>
                    Longer conversations, smarter results
                  </Typography>
                  <Typography style={{ color: 'grey', fontSize: '12px' }}>
                    (GPT 4 32k tokens)
                  </Typography>
                </MenuItem>
                {features.gpt35_turbo_16k ? (
                  <MenuItem
                    value={'GPT_35_TURBO'}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                  >
                    GPT 3.5
                    <Typography style={{ color: 'grey', fontSize: '14px' }}>
                      Older model, still good for most conversations
                    </Typography>
                    <Typography style={{ color: 'grey', fontSize: '12px' }}>
                      (GPT 3.5 Turbo 16k tokens)
                    </Typography>
                  </MenuItem>
                ) : null}
                {features.gpt4 ? <MenuItem value={'GPT_4'}>GPT 4 (non 32k)</MenuItem> : null}
                {features.gpt4_turbo_128k ? (
                  <MenuItem value={'GPT_4_TURBO'}>GPT 4 Turbo</MenuItem>
                ) : null}
              </Select>
            </Tooltip>
          </div>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/chat"
            element={
              <RequireAuth>
                <Chat llm={llm} />
              </RequireAuth>
            }
          />
          <Route
            path="/chat/:conversationId"
            element={
              <RequireAuth>
                <Chat llm={llm} />
              </RequireAuth>
            }
          />
          <Route
            path="/prompts"
            element={
              <RequireAuth>
                <Prompts publishedOnly={true} />
              </RequireAuth>
            }
          />
          <Route
            path="/my-prompts"
            element={
              <RequireAuth>
                <Prompts publishedOnly={false} />
              </RequireAuth>
            }
          />
          <Route
            path="/faqs"
            element={
              <RequireAuth>
                <FAQs />
              </RequireAuth>
            }
          />
          <Route
            path="/user-stats"
            element={
              <RequireAuth>
                <UserStatsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/release-notes"
            element={
              <RequireAuth>
                <ReleaseNotes />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/chat" replace={true} />} />
        </Routes>
      </FullPageContainer>
    </>
  );
}

export default ProvidedApp;
