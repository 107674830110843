import { AutoMode } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Conversation } from 'Types/conversation';
import { Prompt } from 'Types/prompt';
import { HomePageStats } from 'Types/stats';

import ChatStatsIcon from '../../assets/chat-stats-icon.svg';
import DefaultUserStatsIcon from '../../assets/default-user-stats-icon.svg';
import OwlIcon from '../../assets/JBT Alex - Owl.png';
import MessagesStatsIcon from '../../assets/messages-stats-icon.svg';
import { getHomePageStats } from '../../services/stats';

interface TitleBoxProps {
  useFunctions: boolean;
}

const TitleBox = styled('div')<TitleBoxProps>(({ useFunctions }) => ({
  display: 'flex',
  textAlign: 'start',
  justifyContent: 'space-between',
  marginBottom: '30px',
  marginTop: !useFunctions ? '30px' : '0px',
  width: '100%',
}));

const InfoCard = styled(Box)({
  width: '280px',
  minHeight: '305px',
  textAlign: 'start',
  display: 'flex',
  flexDirection: 'column',
  color: 'black',
  outline: '1px solid #eee',
  boxShadow:'0px 4px 12px 6px rgba(16, 24, 40, 0.03)',
  borderRadius:'5px',

  h4: {
    marginLeft: '20px',
    marginTop: '30px',
    marginBottom: '20px',
    color: '#0087CD',
  },
});

const Link = styled('span')({
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
});

const StatsContainer = styled('div')({
  width: '100%',
  justifyContent: 'flex-start',
  display: 'flex',
  alignItems: 'center',
});

const StyledStatsIcon = styled('img')({
  height: '40px',
  marginRight: '20px',
});

export type ChatInfoProps = {
  currentConversation?: Conversation;
  currentPrompt?: Prompt;
  shouldShowInfo: boolean;
  useFunctions: boolean;
};

const ChatInfo = ({
  currentConversation,
  currentPrompt,
  shouldShowInfo,
  useFunctions,
}: ChatInfoProps) => {
  const [stats, setStats] = useState<HomePageStats>({
    numUsers: 0,
    numActiveConversations: 0,
    numMessages: 0,
  });
  const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getHomePageStats();
      setStats(newStats);
      setShouldFetchStats(false);
    };
    if (shouldFetchStats) {
      fetchStats();
    }
  }, [shouldFetchStats]);

  return (
    <div>
      {currentPrompt?.userEditRequired === false ||
      (currentConversation && currentConversation.prompt?.userEditRequired === false) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '70% ' }}>
            <AutoMode sx={{ width: '36px', height: '36px', marginY: '30px' }} />
            <Typography sx={{ fontWeight: '700', fontSize: '36px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.title
                : currentConversation && currentConversation.prompt?.title}
            </Typography>
            <Typography sx={{ fontSize: '20px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.description
                : currentConversation && currentConversation.prompt?.description}
            </Typography>
          </div>
        </div>
      ) : shouldShowInfo ? (
        <div>
          <TitleBox useFunctions={useFunctions}>
            <div style={{ width: '70%' }}>
              <Typography
                variant="h4"
                sx={{ marginBottom: '10px', fontWeight: '700', fontSize: '36px' }}
              >
                Welcome to Alex
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
                Alex is a conversational AI chatbot you can use to generate ideas, <br />
                create draft presentations, or just brainstorm. Happy chatting!
              </Typography>
            </div>
            <img src={OwlIcon} style={{ height: '120px' }} />
          </TitleBox>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <InfoCard sx={{ bgcolor: 'white', borderRadius:'5px', '&:hover': { cursor: 'default' } }}>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                Stats
              </Typography>
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  margin: '0 20px 20px 20px',
                  gap: '30px',
                  display: 'flex',
                }}
              >
                <StatsContainer>
                  <StyledStatsIcon sx={{height:'30px',marginRight:'25px'}} src={DefaultUserStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span> Users
                  </Typography>
                </StatsContainer>
                <StatsContainer>
                  <StyledStatsIcon  sx={{height:'35px',marginLeft:'-1px'}} src={ChatStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numActiveConversations}</span> Chats
                    Today
                  </Typography>
                </StatsContainer>
                <StatsContainer>
                  <StyledStatsIcon sx={{height:'30px',marginRight:'25px'}} src={MessagesStatsIcon} />
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span> Messages
                  </Typography>
                </StatsContainer>
              </div>
            </InfoCard>
            <InfoCard sx={{ borderRadius:'5px', bgcolor: 'white' }}>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                Prompts
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  margin: '0 20px 20px 20px',
                  flexGrow: 1,
                }}
              >
                <Typography>
                  Be sure to check out the prompt library, a.k.a.{' '}
                  <Link onClick={() => navigate('/prompts')}>Prompts</Link>
                  . <br />
                  <br />
                  Think of them as little “head starts” that can help you quickly get the info you
                  want.
                </Typography>
              </div>
            </InfoCard>
            <InfoCard sx={{ bgcolor: 'white', borderRadius:'5px' }}>
              <Typography
                variant="h4"
                sx={{ color: '#062541', fontWeight: '700', fontSize: '24px' }}
              >
                FAQ
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  margin: '0 20px 20px 20px',
                  flexGrow: 1,
                }}
              >
                <Typography>
                  Not sure where to start? Check out our index of{' '}
                  <Link onClick={() => navigate('/faqs')}>common questions</Link> — with super-smart
                  answers!
                </Typography>
              </div>
            </InfoCard>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatInfo;
