import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';

const FeedbackDialog = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit Feedback</DialogTitle>
        <DialogContent>
          <Typography marginBottom={'15px'}>
            To submit any app issues, feedback, or potential improvements, email:
          </Typography>
          <Typography>
            <span
              onClick={() => {
                window.location.replace('mailto:CHI1-GenerativeAI@jbtc.com');
              }}
              style={{ cursor: 'pointer' }}
            >
              <Link>Generative AI Council</Link>
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: '#F36E24' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
