import { Container, styled, Typography } from '@mui/material';
import React from 'react';

const ReleaseNotesDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

const ReleaseNotesList = [
  {
    version: '1.0.0',
    date: '10/20/2023',
    notes: [
      {
        category: 'General Notes',
        bullets: ['Alex Launched firm-wide'],
      },
    ],
  },
];

const ReleaseNotes = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', height: '100%' }}>
      <Container sx={{ bgcolor: 'white', height: 'fit-content', margin: '0px' }}>
        <ReleaseNotesDiv>
          <Typography sx={{ fontSize: '36px', fontWeight: '700', marginBottom: '20px' }}>
            Release Notes
          </Typography>
          {ReleaseNotesList.map(({ version, date, notes }, index: number) => {
            return (
              <div key={index} style={{ marginBottom: '40px' }}>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    marginBottom: '10px',
                  }}
                >
                  <span style={{ paddingRight: '10px' }}>v.{version}</span> {date}
                </Typography>

                {notes.map(({ category, bullets }, index2: number) => {
                  return (
                    <>
                      <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
                        {category}
                      </Typography>
                      <ul key={index2} style={{ marginTop: '5px' }}>
                        {bullets.map((bullet, index3: number) => {
                          return (
                            <li key={index3}>
                              <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>
                                {bullet}
                              </Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            );
          })}
        </ReleaseNotesDiv>
      </Container>
    </div>
  );
};

export default ReleaseNotes;
