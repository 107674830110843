export enum MessageFrom {
  AI = 'AI',
  USER = 'USER',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum Tag {
  GENERAL = 'General',
  SUMMARIZE = 'Summarize',
  NOTES = 'Notes',
  EMAIL_AID = 'Email Aid',
  PRODUCTIVITY = 'Productivity',
  EXTERNAL_CUSTOMER_FOCUSED = 'External Customer Focused',
  OPERATIONS = 'Operations',
  HR = 'HR',
  COMMUNICATIONS = 'Communications',
  FINANCE = 'Finance',
  LEGAL = 'Legal',
  DIGITAL_AND_IT = 'Digital and IT',
  RESEARCH = 'Research',
}

export enum FunctionName {
  GREETINGS = 'greetings',
}

export const FunctionNameMap = {
  [FunctionName.GREETINGS]: 'Greetings',
};

export enum PromptSortOptions {
  LIKES = 'LIKES',
  USAGE = 'USAGE',
  DEFAULT = 'DEFAULT',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
