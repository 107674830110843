import './init';

import { createTheme, Grow, ThemeProvider } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './components/Providers/AuthProvider';
import { ErrorProvider } from './components/Providers/ErrorProvider';
import { FeatureProvider } from './components/Providers/FeatureProvider';
import { JoyrideProvider } from './components/Providers/JoyrideProvider';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import ProvidedApp from './ProvidedApp';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),
          textTransform: 'none',
          borderRadius: '0px',
        },
        contained: {
          backgroundColor: '#0076cc',
          '&:hover': {
            backgroundColor: '#0693e3',
          },
          '&:active': {
            backgroundColor: '#0693e3',
          },
        },
        outlined: {
          color: '#092b49',
          borderColor: '#092b49',

          ':hover': {
            color: '#135996',
            borderColor: '#135996',
          },

          ':active': {
            color: '#1C86E3',
            borderColor: '#1C86E3',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),

          '& > *': {
            fontFamily: ['Noto Sans'].join(','),
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: ['Noto Sans'].join(','),
        },
      },
    },
  },
});

function App() {
  // For adding a close button to snackbars
  const SnackbarCloseButtonAction = useCallback(
    (key: SnackbarKey) => <SnackbarCloseButton snackbarKey={key} />,
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={15000}
        preventDuplicate={true}
        TransitionComponent={Grow}
        action={(key) => SnackbarCloseButtonAction(key)}
      >
        <BrowserRouter>
          <AuthProvider>
            <ErrorProvider>
              <JoyrideProvider>
                <FeatureProvider>
                  <ProvidedApp />
                </FeatureProvider>
              </JoyrideProvider>
            </ErrorProvider>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
