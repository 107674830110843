import { QuestionAnswer } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import JBT_LOGO from '../../assets/JBT-logo.webp';
import { useFeatures } from '../../components/Providers/FeatureProvider';
import { FunctionName, FunctionNameMap } from '../../Types/enums';

const StyledFormControl = styled(FormControl)({
  flexDirection: 'row',
  verticalAlign: 'top',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  cursor: 'pointer',

  '&.Mui-focused': {
    color: theme.palette.text.secondary,
  },
}));

const StyledSelect = styled(Select)({
  boxShadow: 'none',
  width: '30px',

  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

interface IconContainerProps {
  active: boolean;
}

const IconContainer = styled('span')<IconContainerProps>(({ active }) => ({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',

  span: {
    color: active ? 'black' : 'gray',
    marginLeft: '5px',
  },
}));

export type FunctionSettingsProps = {
  functions: FunctionName[];
  disabledOnFunctions: FunctionName[];
  handleFunctionsUpdate: (funcNames: FunctionName[]) => void;
  useFunctions: boolean;
  setUseFunctions: (newValue: boolean) => void;
};

const FunctionSettings = ({
  functions,
  disabledOnFunctions,
  handleFunctionsUpdate,
  useFunctions,
  setUseFunctions,
}: FunctionSettingsProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const features = useFeatures();

  const uniqueFuncList = Array.from(new Set(functions.concat(disabledOnFunctions)));

  if (!features.show_functions) {
    return <></>;
  }

  return (
    <div>
      <ToggleButtonGroup
        value={useFunctions ? 'Functions' : 'Standard'}
        exclusive
        onChange={() => {
          setUseFunctions(!useFunctions);
        }}
      >
        <Tooltip
          placement="top"
          title={
            <Typography variant="caption"> Simply uses the raw Chat GPT Functionality </Typography>
          }
        >
          <ToggleButton value="Standard">
            <IconContainer active={!useFunctions}>
              <QuestionAnswer />
              <span>Standard</span>
            </IconContainer>
          </ToggleButton>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <Typography variant="caption">
              Allows for the addition of functions which can query other data sources
            </Typography>
          }
        >
          <ToggleButton value="Functions">
            <IconContainer active={useFunctions}>
              <img src={JBT_LOGO} />
              <span>JBT Data</span>
            </IconContainer>
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      {useFunctions && (
        <div>
          <StyledFormControl>
            <StyledFormLabel
              onClick={() => {
                setOpen(true);
              }}
            >
              Functions {uniqueFuncList.length > 0 ? <Chip label={uniqueFuncList.length} /> : null}
            </StyledFormLabel>
            <StyledSelect
              multiple
              value={uniqueFuncList || []}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event) => {
                handleFunctionsUpdate(event.target.value as FunctionName[]);
              }}
              renderValue={() => null}
            >
              {Object.values(FunctionName).map((functionName, index) => {
                return (
                  <MenuItem key={index} value={functionName}>
                    <Checkbox
                      checked={
                        disabledOnFunctions.includes(functionName) ||
                        functions.includes(functionName)
                      }
                      disabled={disabledOnFunctions.includes(functionName)}
                    />
                    <ListItemText primary={FunctionNameMap[functionName]} />
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </StyledFormControl>
        </div>
      )}
    </div>
  );
};

export default FunctionSettings;
