import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import jbtAlex from '../assets/JBT-alex.svg';
import blueSwoosh from '../assets/JBT-blue-swoosh.svg';
import greySwoosh from '../assets/JBT-grey-swoosh.svg';
import { useAuth } from '../components/Providers/AuthProvider';

const MainContainer = styled('div')({
  height: 'inherit',
  display: 'flex',
});

const LoginPage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'inherit',
  minWidth: '50%',
});

const LoginSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  marginLeft: '10px',
  marginRight: '10px',
  width: '360px',
  height: 'fit-content',
  zIndex: 2,
  gap: '40px',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  maxWidth: '100%',
  overflowX: 'hidden',
  minWidth: '50%',
});

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  // use the auth context to get the login function
  const { currentUser, authenticateAzureAD } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const ssoLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirect') || '/';
    authenticateAzureAD(redirectTo);
  };

  return (
    <MainContainer>
      <img
        src={greySwoosh}
        style={{ position: 'absolute', bottom: 0, left: 0, rotate: '180deg' }}
      />
      <img src={blueSwoosh} style={{ position: 'absolute', top: 0, right: 0, rotate: '180deg' }} />
      <LoginPage>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoginSection>
            <img src={jbtAlex}></img>
            <Button
              onClick={ssoLogin}
              variant="contained"
              sx={{ bgcolor: '#F36E24', '&:hover': { bgcolor: '#C14610' } }}
            >
              Login with SSO
            </Button>
          </LoginSection>
        </div>
      </LoginPage>
      <ImageContainer
        style={{
          backgroundImage: 'linear-gradient(to right, #0077cd, #00cfff)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', color: 'white' }}>
          <Typography sx={{ textAlign: 'justify', width: '400px', fontSize: '20px' }}>
            &quot;Technology alone is not enough. It&apos;s technology married with the liberal
            arts, married with the humanities, that yields the results that make our hearts
            sing.&quot;
          </Typography>
          <Typography sx={{ textAlign: 'justify', width: '400px', fontSize: '20px' }}>
            <i>Steve Jobs</i>
          </Typography>
        </div>
      </ImageContainer>
    </MainContainer>
  );
};

export default Login;
