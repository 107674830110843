import { Container, styled, Typography } from '@mui/material';
import React from 'react';

const FAQPageDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

const QuestionTypeTitle = styled(Typography)({
  variant: 'subtitle1',
  fontWeight: 'bold',
  marginBottom: '20px',
  color: '#0087CD',
});

const QuestionLinkContainer = styled(Typography)({
  variant: 'subtitle2',
  marginBottom: '25px',

  a: {
    color: 'black',
  },
});

interface QuestionAnswer {
  question: string;
  answer: string;
}
const FAQList = {
  General: [
    {
      question: 'Where did this tool’s name (Alex) come from?',
      answer:
        'Alex was a name sourced from some of your colleagues. It can mean ‘Automated Logic and Expertise’. Also, the name Alex has origins to mean ‘Protector of Humankind’. JBT believes that AI can be of great benefit to our business but a ‘human in the loop’ is always needed to review the output that Alex can provide.',
    },
  ],
  Capabilities: [
    {
      question: 'What is the difference between the “Chat” and “Prompts” main menu options?',
      answer:
        'The “Chat” option works just like common Generative AI solutions like ChatGPT. You can chat in the main window on the right and see your previous chats on the left. The “Prompt” section of Alex allows you to browse pre-engineered prompts that have been created to support specific tasks (like write user stories) or workflows (like ingesting notes and providing specifically formatted output).',
    },
    {
      question: 'What can Alex help me with?',
      answer:
        'Alex can help you with a wide range of tasks, including providing information on industry trends, answering technical questions, offering project ideas, and assisting with data analysis.',
    },
    {
      question: 'How does Alex handle sensitive or confidential information?',
      answer:
        'Alex is part of JBT’s internal Microsoft environment. It is secure. Information you give to Alex will not get propagated outside of JBT. However, please remember to use Alex as you would any other technology tool provided to you, and to follow the standard IT G.250/G.255 Policies. As we connect Alex to internal data sources, we need to treat access to data through Alex like we do in other systems. Additionally, use of Alex is governed by the Generative AI Policy G285.',
    },
    {
      question: 'What is the accuracy of the responses provided by Alex?',
      answer:
        'Alex strives to provide accurate responses based on its training and knowledge base. However, there may be instances where the responses may not be completely accurate or require further verification. It is always recommended to validate the information provided by the chatbot with subject matter experts.',
    },
    {
      question: 'Can Alex assist with generating reports or documents?',
      answer:
        'Yes, Alex can assist with generating reports or documents based on predefined templates or formats. It can provide information and insights that can be used in report generation, but the final output may require additional human review and refinement.',
    },
    {
      question: 'Can Alex handle multiple languages?',
      answer:
        'Yes, including coding languages. This allows users to interact with the chatbot in their preferred language, enhancing the overall user experience.',
    },
    {
      question: 'What are the main differences between GPT-3.5 and GPT-4?',
      answer:
        'While both GPT-3.5 and GPT-4 are language prediction models developed by OpenAI, GPT-4 has been further enhanced to provide more accurate and contextual responses. GPT-4 is more expensive to use. For this reason, if you wish to use GPT-4, please make a request through the IT Service Desk. Alex is currently offered as GPT-3.5 as a default.',
    },
    {
      question: 'Are there any significant changes in the usage or application of these models?',
      answer:
        'While both models can be used for a wide range of applications, the improvements in GPT-4 make it even more effective in areas like content creation, customer service, tutoring, and more. However, the specifics of usage may vary based on the requirements of the task and the resources available.',
    },
  ],
  Usage: [
    {
      question: 'Is it okay to put JBT data in Alex?',
      answer:
        'Alex is the only current Generative AI tool that is sanctioned by JBT for use with JBT. Using Generative AI tools other than Alex, with JBT or client data, is not allowed at this time. If you have a use case and wish to connect Alex to a new internal source of data, please contact the AI Council (through the link to do so in Alex)',
    },
    {
      question: 'Why is Alex taking a long time to respond?',
      answer:
        "Alex's response time will correlate with the length of his response. As a general rule of thumb, if you ask a complex or long question, Alex will take longer to respond.",
    },
    {
      question: 'Do I have to disclose that I used Alex when using its outputs?',
      answer:
        'Yes. Under the company guidelines, you should disclose if you used any generative AI tools to produce work.',
    },
    {
      question: 'Is there a limit to the number of questions I can ask Alex?',
      answer:
        'The limit is based on tokens, while not an exact parallel, you can think of tokens like a character limit. The limit is very high for the average user, but can reach a maximum if you use a single chat to perform a complex task. A good rule of thumb is if you change topics, simply create a new chat.',
    },
  ],
  'Administration and Support': [
    {
      question: 'How do I report a bug or issue with Alex?',
      answer:
        'To report a bug or issue with Alex, please contact the Generative AI Council (CHI1-GenerativeAI@jbtc.com). Provide detailed information about the problem encountered, including any error messages or steps to reproduce the issue.',
    },
    {
      question: 'Who can access Alex tool within our organization?',
      answer:
        'Alex is accessible through your SSO login, so all active JBT employees can use Alex.',
    },
    {
      question: 'How do I request a new feature or enhancement for Alex?',
      answer: `To request a new feature or enhancement for Alex, contact the Generative AI Council (CHI1-GenerativeAI@jbtc.com). We'd love to hear from you as we continue to improve the tool.`,
    },
    {
      question: 'Can I add prompts to the prompt library?',
      answer:
        "Yes! We'd love to hear your prompt ideas and work with you to add them. Please contact the Generative AI Council (CHI1-GenerativeAI@jbtc.com) to get started.",
    },
    {
      question: 'Who should I contact if I have additional questions or need support with Alex?',
      answer:
        'For additional questions or support with Alex, please contact the Generative AI Council (CHI1-GenerativeAI@jbtc.com).',
    },
  ],
};

const FAQ = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', height: '100%' }}>
      <Container sx={{ bgcolor: 'white', height: 'fit-content', margin: '0px' }}>
        <FAQPageDiv>
          <Typography variant="h4" style={{ fontWeight: '700', fontSize: '36px' }}>
            Frequently Asked Questions
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>General</QuestionTypeTitle>
              {FAQList['General'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#g${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
              <QuestionTypeTitle>Capabilities</QuestionTypeTitle>
              {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#c${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
            </div>

            <div style={{ width: '45%' }}>
              <QuestionTypeTitle>Usage</QuestionTypeTitle>
              {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
                return (
                  <QuestionLinkContainer key={index}>
                    <a
                      href={`#u${index}`}
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      {questionAnswer.question}
                    </a>
                  </QuestionLinkContainer>
                );
              })}
              <QuestionTypeTitle>Administration and Support</QuestionTypeTitle>
              {FAQList['Administration and Support'].map(
                (questionAnswer: QuestionAnswer, index: number) => {
                  return (
                    <QuestionLinkContainer key={index}>
                      <a
                        href={`#a${index}`}
                        style={{
                          marginBottom: '30px',
                        }}
                      >
                        {questionAnswer.question}
                      </a>
                    </QuestionLinkContainer>
                  );
                }
              )}
            </div>
          </div>

          <br />
          <br />
          <Typography sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
            General
          </Typography>
          {FAQList['General'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`g${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px' }}>
            Capabilities
          </Typography>
          {FAQList['Capabilities'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`c${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Usage
          </Typography>
          {FAQList['Usage'].map((questionAnswer: QuestionAnswer, index: number) => {
            return (
              <div id={`u${index}`} key={index}>
                <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                  {questionAnswer.question}
                </Typography>
                <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
              </div>
            );
          })}
          <Typography
            sx={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', marginTop: '20px' }}
          >
            Administration and Support
          </Typography>
          {FAQList['Administration and Support'].map(
            (questionAnswer: QuestionAnswer, index: number) => {
              return (
                <div id={`a${index}`} key={index}>
                  <Typography sx={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                    {questionAnswer.question}
                  </Typography>
                  <Typography style={{ marginBottom: '30px' }}>{questionAnswer.answer}</Typography>
                </div>
              );
            }
          )}
        </FAQPageDiv>
      </Container>
    </div>
  );
};

export default FAQ;
