import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { login, loginAzureAD, logoutUser } from '../../services/api';
import { User } from '../../Types/user';

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  authenticate: (email: string, password: string, redirectTo: string) => void;
  authenticateAzureAD: (redirectTo: string) => void;
  logout: (redirect?: string) => void;
  initialized: boolean;
}

const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

export const AuthContext = createContext<AuthContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

async function fetchProfilePhoto() {
  const profilePhotoData = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('graphToken')}`,
    },
  });

  if (profilePhotoData.status === 200) {
    const photoData = await profilePhotoData.blob();
    localStorage.setItem('profilePhoto', URL.createObjectURL(photoData));
  } else {
    localStorage.setItem('profilePhoto', '');
  }
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const logout = useCallback(
    async (redirectTo?: string) => {
      try {
        await logoutUser();
        setCurrentUser(null);
        sessionStorage.clear();
        localStorage.clear();
      } catch (error) {
        setCurrentUser(null);
        enqueueSnackbar('Error logging out', { variant: 'error' });
      }
      if (redirectTo) {
        navigate(redirectTo, { replace: true });
      }
    },
    [navigate]
  );

  useEffect(() => {
    const checkLogin = async () => {
      let user = null;
      try {
        setInitialized(false);
        await instance.initialize();
        const authResult = await instance.handleRedirectPromise();

        const token = await instance.acquireTokenSilent({
          scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
          account: instance.getAllAccounts()[0],
        });
        const graphToken = await instance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: instance.getAllAccounts()[0],
        });

        localStorage.setItem('token', token.accessToken);
        localStorage.setItem('graphToken', graphToken.accessToken);
        await fetchProfilePhoto();

        user = await loginAzureAD();
        setCurrentUser(user);
        setIsAdmin(user.role === 'ADMIN');
        if (authResult?.state) {
          window.location.href = authResult.state;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };
    checkLogin();
    // Including the navigate dep causes an infinite loop, but using react router to navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const authenticate = async (email: string, password: string, redirectTo: string) => {
    const user = await login(email, password);
    setCurrentUser(user);
    setIsAdmin(user.role === 'ADMIN');
    navigate(redirectTo);
  };

  const authenticateAzureAD = async (redirectTo: string) => {
    await instance.loginRedirect({
      scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
      redirectUri: `${window.location.origin}/chat`,
      state: redirectTo,
    });
  };

  if (!initialized || loading) {
    return (
      <SpinnerContainer>
        <CircularProgress />
      </SpinnerContainer>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, authenticate, authenticateAzureAD, logout, initialized, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
};

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { currentUser, initialized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !currentUser) {
      navigate(`/login?redirect=${location.pathname}${location.search}`, { replace: true });
    }
  }, [currentUser, initialized, navigate, location]);

  if (!initialized) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};
