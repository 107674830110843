import { Typography } from '@mui/material';
import React from 'react';
import { Placement } from 'react-joyride';

export const GuidedSteps = [
  {
    title: 'GPT 4 is here!',
    target: '#llm-select',
    disableBeacon: true,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          GPT 4 is here and enabled by default for everyone.
        </Typography>
        <ul style={{ display: 'flex', flexDirection: 'column' }}>
          <li style={{ textAlign: 'start' }}>
            Twice the context window of the old 3.5 model, which means you can have conversations
            that are twice as long
          </li>
          <li style={{ textAlign: 'start' }}>Generally better reasoning and responses</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'New Model Filters',
    target: '#filter-container',
    placement: 'right' as Placement,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          New tags were added to specify the recommended model
        </Typography>
        <Typography style={{ textAlign: 'start' }}>
          Some prompts will work better on GPT 3.5 or 4. Please choose the appropriate model when
          needed.
        </Typography>
      </div>
    ),
  },
];
