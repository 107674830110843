import { Person } from '@mui/icons-material';
import { keyframes } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import remarkGfm from 'remark-gfm';

import OWL_LOGO from '../../assets/JBT Alex - Owl.png';
import SPINNING_LOGO from '../../assets/spinning-icon.svg';
import { MessageFrom } from '../../enums';
import { Message } from '../../Types/conversation';
import MarkdownTable from '../MarkdownTable';
import CodeCopyButton from './CodeCopyButton';

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)({
  borderRadius: '4px',
});

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ChatBubbleContainer = styled('div')({
  fontSize: '14px',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const ChatIcon = styled('img')({
  width: '20px',
});

const SpinningChatIcon = styled(ChatIcon)({
  animation: `${rotateAnimation} 2s linear infinite`,
});

const PersonIcon = styled(Person)({
  width: '20px',
});

const CodePre = styled('pre')({
  position: 'relative',
  overflowX: 'auto',
});

const ChatCard = styled('div')(({ sender }: { sender: MessageFrom }) => ({
  borderRadius: '4px',

  margin: '2px 0',
  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  boxSizing: 'border-box',
  position: 'relative',
  padding: '1rem',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: sender !== 'AI' ? '#eaf1f5' : '#fff',
}));

const renderMessage = (message: string, citations: string[]) => {
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}`;
    };
    return formatToMarkdown(message, citations);
  }

  return message;
};

const ChatBubbleComponent = ({
  message,
  loading = false,
}: {
  message: Partial<Message>;
  loading?: boolean;
}) => {
  return (
    <ChatCard
      sender={message.from ?? MessageFrom.AI}
      sx={{ opacity: loading ? '40%' : '100%', transition: '0.5s' }}
    >
      <>
        {message.from === MessageFrom.USER ? (
          <PersonIcon />
        ) : loading ? (
          <SpinningChatIcon src={SPINNING_LOGO} alt="JBT Logo" />
        ) : (
          <ChatIcon src={OWL_LOGO} alt="JBT Logo" />
        )}
        <ChatBubbleContainer sx={{ lineHeight: '1.5rem' }}>
          {message.from === MessageFrom.AI ? (
            <ReactMarkdown
              linkTarget={'_blank'}
              remarkPlugins={[remarkGfm]}
              components={{
                pre: ({ className, children }) => (
                  <CodePre className={className}>
                    <CodeCopyButton>{children}</CodeCopyButton>
                    {children}
                  </CodePre>
                ),
                table: (props) => <MarkdownTable {...props} />,
                code({ inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <StyledSyntaxHighlighter
                      {...props}
                      language={match[1]}
                      style={a11yDark}
                      PreTag="div"
                    >
                      {String(children).replace(/\n$/, '')}
                    </StyledSyntaxHighlighter>
                  ) : (
                    <code {...props} style={{ wordWrap: 'normal' }} className={className}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {renderMessage(message.message ?? '', message.citations ?? [])}
            </ReactMarkdown>
          ) : (
            <p style={{ whiteSpace: 'pre-wrap' }}>{message.message}</p>
          )}
        </ChatBubbleContainer>
      </>
    </ChatCard>
  );
};

export default ChatBubbleComponent;
